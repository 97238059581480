/* LIBRARY EXHIBITS STYLES */

html {
    overflow-x: hidden;
}

.exhibit-container {
    padding-top: 50px;
}

.exhibit-container .breadcrumb {
    margin: 1rem 0 1rem 0;
    background-color: $white-body;

    &>.active {
        color: $wcagaa-compliant-gray-text;
    }
}

#exhibit, #exhibit_archive {
    position: relative;
    display: inline-block;
    height: 100%;
    width: 100%;
}

.latest_exhibit {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    z-index: 1;
    padding: 275px 0 0;
}

@media ( max-width: 1199px ) {
    .latest_exhibit {
        padding: 0 0 0;
    }
}

#exhibit .latest_exhibit h3 {
    font-size: 22px;
    margin-top: 0;
}

#exhibit .latest_exhibit .left h3 {
    text-align: center;
}

#exhibit .latest_exhibit .left {
    float: left;
    width: 25%;
    background: $very-light-gray;
    border-bottom: 5px solid $very-light-gray;
    padding: 10px 20px 0;
}

@media ( max-width: 1199px ) {
    #exhibit .latest_exhibit .left {
        display: none;
    }
}

#exhibit .latest_exhibit .left ul {
    margin: 0 0 20px;
    padding: 0 0 0 15px;
}

#exhibit .latest_exhibit .left ul li {
    list-style: none outside none;
    margin: 0;
    padding: 0 0 10px;
}

/* li for digitial exhibits wraps around to two lines */
#exhibit .latest_exhibit .left ul li.digital-exhibits-li {
    line-height: 1.6;
}

#exhibit .latest_exhibit .left ul li a:hover {
    text-decoration: none;
    border-bottom: 1px solid $red;
    margin: 0 0 10px;
}

#exhibit .latest_exhibit .left .target {
    color: #666;
    border-bottom: 1px solid $red;
    padding: 0;
    margin: 0 0 10px;
}

#exhibit .latest_exhibit .right {
    float: right;
    width: 75%;
    padding: 10px 20px 0;
}

@media ( max-width: 1199px ) {
    #exhibit .latest_exhibit .right {
        float: right;
        width: 100%;
        padding: 10px 20px 0;
    }
}

#exhibit .latest_exhibit .right ul {
    margin: 0;
    padding: 0;
    clear: both;
    display: inline-block;
}

@media ( max-width: 1199px ) {
    #exhibit .latest_exhibit .right ul {
        width: 100%;
    }
}

#exhibit .latest_exhibit .holder {
    display: inline-block;
    position: relative;
    margin: 0 0 30px 0;
    padding: 0;
    width: 375px;
    height: auto;
}

@media ( max-width: 1199px ) {
    #exhibit .latest_exhibit .holder {
        display: inline-block;
        position: relative;
        margin: 0 0 15px 0;
        padding: 0;
        width: 40%;
        height: auto;
    }
}

@media ( max-width: 768px ) {
      #exhibit .latest_exhibit .holder {
        display: inline-block;
        position: relative;
        margin: 0 auto 30px;
        padding: 0;
        width: 100%;
        height: auto;
    }
}

#exhibit .latest_exhibit .holder:nth-child(odd) {
   float: right;
}

#exhibit .latest_exhibit .holder:nth-child(even) {
   float: left;
}

#exhibit .latest_exhibit .holder .holdhead {
    position: relative;
}

#exhibit .latest_exhibit .holdhead h4 {
    border-bottom: 1px solid $dark-blue-green;
    margin: 5px 0 0 !important;
}

#exhibit .latest_exhibit .holder .holdhead p {
    font-size: 12px;
}

#exhibit .latest_exhibit .holder .frame {
    border: 2px solid $dark-blue-green;
    position: relative;
    color: #444;
    height: 375px;
    width: 375px;
}

@media ( max-width: 1199px ) {
    #exhibit .latest_exhibit .holder .frame {
        height: auto;
        width: 100%;
    }
}

@media ( max-width: 768px ) {
    #exhibit .latest_exhibit .holder .frame {
        border: 2px solid $dark-blue-green;
        position: relative;
        color: #444;
        height: 100%;
        width: auto;
    }
    .exhibit-container .breadcrumb {
        margin: 1rem 0 1rem 0;
    }
}

#exhibit .latest_exhibit .holder .frame:hover {
    border: 2px solid #32758F;
}

#exhibit .latest_exhibit .holder .frame img {
    height: 100%;
    width: 100%;
}

#exhibit .latest_exhibit .holder .frame .info {
    bottom: 0;
    position: absolute;
    width: 100%;
    background: rgba(255, 255, 255, 0.8) none repeat scroll 0 0;
    min-height: 65px;
    padding: 10px 5px 5px;
    border-top: 1px solid $dark-blue-green;
}

#exhibit .latest_exhibit .holder .frame .info .date {
    font-size: 12px;
    margin: 0;
    font-style: italic;
    color: #444;
}

#exhibit .latest_exhibit .holder .frame .info .title {
    font-weight: bold;
    line-height: 1;
    font-size: 15px;
}

#exhibit .latest_exhibit .holder .frame:hover .info .title {
    color: #32758F;
}

#exhibit .above_footer {
    clear: both;
}

/* EXHIBIT BLACKBAND HEADER STYLING AND CUSTOMIZATION */

.blackband {
    display: inline-block;
    position: absolute;
    left: calc(-50vw + 50%);
    width: 100vw;
    min-height: 150px;
    height: 290px;
    background: url("https://library.bc.edu/images/exhibits/banner_default.jpg") no-repeat 0 100% $very-dark-gray;
    border-top: 1px solid $very-light-gray;
    border-bottom: 1px solid $very-light-gray;
    color: $white-text;
    padding: 30px 0;
    z-index: 2;
    background-size: cover;
}

@media ( max-width: 1199px ) {
    .blackband {
    display: inline-block;
    position: relative;
    width: 100%;
    min-height: 150px;
    height: auto;
    background: url("https://library.bc.edu/images/exhibits/banner_default.jpg") no-repeat 0 100% $very-dark-gray;
    border-top: 1px solid $very-light-gray;
    border-bottom: 1px solid $very-light-gray;
    color: $white-text;
    padding: 30px 0;
    z-index: 2;
    background-size: cover;
    }
}

.blackband h2 {
    font-size: 36px;
    color: $white-text;
    text-align: center;
    border-bottom: 1px solid $white-body;
    margin: 0 0 20px 0;
}

@media ( max-width: 1199px ) {
    .blackband h2 {
    font-size: 24px;
    }
}

.blackband .bandcenter {
    width: 50%;
    max-width: 610px;
    margin: 0 auto;
    position: relative;
    display: block;
    text-align: justify;
    text-shadow: 2px 2px 1px $black-text;
}

.exhibit_page .blackband .bandcenter {
    text-align: center;
    width: 875px;
    max-width: 875px;
}

@media ( max-width: 1199px ) {
    .blackband .bandcenter, .exhibit_page .blackband .bandcenter {
        width: 75%;
        max-width: 100%;
        padding: 0 20px;
    }
}

@media ( max-width: 768px ) {
    .blackband .bandcenter, .exhibit_page .blackband .bandcenter {
        width: 100%;
        max-width: 100%;
        padding: 0 20px;
    }
}

#exhibit .burns, .exhibit_page .burns {
    background: url("https://library.bc.edu/images/exhibits/banner_burns.jpg") no-repeat 50% 0 $very-dark-gray;
}

#exhibit .tml, .exhibit_page .tml {
    background: url("https://library.bc.edu/images/exhibits/banner_tml.jpg") no-repeat 50% 0 $very-dark-gray;
}

#exhibit .lvl1, .exhibit_page .lvl1 {
    background: url("https://library.bc.edu/images/exhibits/banner_lvl1.jpg") no-repeat 50% 0 $very-dark-gray;
}

#exhibit .lvl3, .exhibit_page .lvl3 {
    background: url("https://library.bc.edu/images/exhibits/banner_lvl3.jpg") no-repeat 50% 0 $very-dark-gray;
}

#exhibit .reading, .exhibit_page .reading {
    background: url("https://library.bc.edu/images/exhibits/banner_reading.jpg") no-repeat 50% 0 $very-dark-gray;
}

#exhibit .backwall, .exhibit_page .backwall {
    background: url("https://library.bc.edu/images/exhibits/banner_backwall.jpg") no-repeat 50% 0 $very-dark-gray;
}

#exhibit .lobby, .exhibit_page .lobby {
    background: url("https://library.bc.edu/images/exhibits/banner_oneill.jpg") no-repeat 50% 0 $very-dark-gray;
}

#exhibit .stokes, .exhibit_page .stokes {
    background: url("https://library.bc.edu/images/exhibits/banner_stokes.jpg") no-repeat 50% 0 $very-dark-gray;
}

/*#exhibit .tiponeill, .exhibit_page .tiponeill {
    background: url("https://library.bc.edu/images/exhibits/banner_tiponeill-tinted.jpg") no-repeat 0% 90% $very-dark-gray;
}*/

#exhibit .bapst, .exhibit_page .bapst, #exhibit .bapstarchive, .exhibit_page .bapstarchive  {
    background: url("https://library.bc.edu/images/exhibits/banner_bapst.jpg") no-repeat 50% 0 $very-dark-gray;
}

#exhibit .virtual, .exhibit_page .virtual {
    background: url("https://library.bc.edu/images/exhibits/banner_default.jpg") no-repeat 0 100% $very-dark-gray;
}

#exhibit .tip, .exhibit_page .tip {
    background: url("https://library.bc.edu/images/exhibits/banner_default.jpg") no-repeat 0 100% $very-dark-gray;
}

.blackband a {
    color: $white-text;
    text-decoration: underline;
}

.blackband a:hover {
    font-weight: bold;
}

/* EXHIBIT ARCHIVE STYLING */

#exhibit.archives .latest_exhibit .holder:nth-child(odd), #exhibit.archives .latest_exhibit .holder:nth-child(even) {
   float: left;
   margin: 0 25px 25px 0;
}

@media ( max-width: 1199px ) {
    #exhibit.archives .latest_exhibit .holder:nth-child(odd) {
       float: right;
       margin: 0 0 25px 0;
    }

    #exhibit.archives .latest_exhibit .holder:nth-child(even) {
        float: left;
        margin: 0 0 25px 0;
    }
}

@media ( max-width: 768px ) {
    #exhibit.archives .latest_exhibit .holder:nth-child(odd) {
       float: none;
       margin: 0 0 25px 0;
    }

    #exhibit.archives .latest_exhibit .holder:nth-child(even) {
        float: none;
        margin: 0 0 25px 0;
    }
}

#exhibit.archives .latest_exhibit .holdhead {
    margin: 0 0 10px 0;
    width: 375px;
}

#exhibit.archives .latest_exhibit .holder {
    display: inline-block;
    position: relative;
    margin: 0 0 50px 0;
    padding: 0;
    width: 250px;
    height: auto;
}

#exhibit.archives .latest_exhibit .holder .frame {
    border: 2px solid $dark-blue-green;
    position: relative;
    color: #444;
    height: 250px;
    width: 250px;
}

@media ( max-width: 1199px ) {
    #exhibit.archives .latest_exhibit .holder {
        margin: 0 0 25px 0;
        padding: 0;
        width: 45%;
        height: auto;
    }

    #exhibit.archives .latest_exhibit .holder .frame {
        height: auto;
        width: 100%;
    }
}

@media ( max-width: 767px ) {
    .exhibit-container {
        padding-top: 60px;
    }
    #exhibit.archives .latest_exhibit .holder {
        margin: 0 0 25px 0;
        padding: 0;
        width: 100%;
        height: auto;
    }
    #exhibit.archives .latest_exhibit .holdhead {
        width: 100%;
    }
}

#exhibit.archives .latest_exhibit .holder .frame:hover {
    border: 2px solid #32758F;
}

#exhibit.archives .latest_exhibit .holder .frame img {
    height: 100%;
    width: 100%;
}

#exhibit.archives .latest_exhibit .holder .frame .info {
    bottom: 0;
    position: absolute;
    width: 100%;
    background: rgba(255, 255, 255, 0.9) none repeat scroll 0 0;
    min-height: 55px;
    padding: 5px;
}

#exhibit.archives .latest_exhibit .holder .frame .info .date {
    font-size: 12px;
    margin: 0;
    font-style: italic;
    color: #444;
}

#exhibit.archives .latest_exhibit .holder .frame .info .title {
    font-weight: bold;
    line-height: 1;
    font-size: 15px;
}

#exhibit.archives .latest_exhibit .holder .frame:hover .info .title {
    color: #32758F;
}


/* INDIVIDUAL EXHIBIT PAGE STYLES */

#individualpage {
    padding: 205px 0 0;
    z-index: 3;
}

.exhibit_page .exhibittitle {
    width: 75%;
    min-height: 120px;
    padding: 10px 5px 0;
    margin: 0 auto 25px;
    display: block;
    background: $white-body;
    position: relative;
    text-align: center;
    z-index: 3;
    box-shadow: 0px -15px 12px 1px rgba(0,0,0,0.35);
}

.exhibit_page .exhibittitle h3 {
    font-size: 25px;
    text-shadow: 2px 2px 1px #b8b8b8;
}

.exhibit_page .exhibittitle h3 .subtitle {
    font-size: 18px;
    text-shadow: 1px 1px 1px #b8b8b8;
}

.exhibit_page .exhibittitle p {
    font-size: 12px;
}

.exhibit_page .article_content, .exhibit_page .article_content_two {
    width: 90%;
    margin: 0 auto;
}

.article_content .poster, .article_content_two .poster {
    border: 2px solid $dark-blue-green;
    padding: 2px;
}

.article_content .poster img, .article_content_two .poster img  {
    width: 100%;
    max-width: 100%;
}

.article_content .text, .article_content_two .text  {
    text-align: justify;
}

.digital-exhibits-link {
    font-weight: bold;
    padding: 2rem 0;
}

@media ( max-width: 1199px ) {
    #individualpage {
    padding: 0 0 25px;
    z-index: 3;
}

.exhibit_page .exhibittitle {
    width: 100%;
    min-height: 120px;
    padding: 10px 5px 0;
    margin: 0 auto 25px;
    display: block;
    background: $white-body;
    position: relative;
    text-align: center;
    z-index: 3;
    box-shadow: 0px -15px 12px 1px rgba(0,0,0,0);
}
    }

/* FOR NON-POSTER IMAGES THAT APPEAR IN SOME EXHIBIT PAGES */

.exhibit_page .article_content .article_content .text img {
    border: 2px solid $dark-blue-green;
    padding: 2px;
    margin-bottom: 10px;
}

.exhibit_page .article_content .article_content .text img.center {
    margin: 0 auto 10px;
}


/* SPECIFIC EXHIBIT STYLES */

.default .article_content .poster, .left .article_content .poster  {
    float: left;
    margin: 0 25px 10px 0;
    max-width: 50%;
}

.default .article_content .text, .left .article_content .text  {
    padding: 0 95px 0 0;
}

.right .article_content .poster {
    float: right;
    margin: 0 0 10px 25px;
    max-width: 50%;
}

.right .article_content .text {
    padding: 0 0 0 95px;
}

.middle_top .article_content .poster {
    float: none;
    margin: 0 auto 15px;
    max-width: 875px;
    width: 50%;
}

#exhibit.tiponeill .poster figure, .exhibit_page.tiponeill .poster figure {
    margin: 2rem 0;
    font-size: small;
}

#exhibit.tiponeill .poster figure img, .exhibit_page.tiponeill .poster figure img {
    width: 100%;
    max-width: 100%;
}

#exhibit.tiponeill .latest_exhibit .right ul {
    padding: 0 0 1rem 4rem;
}

.middle_top .article_content .text {
   max-width: 875px;
   margin: 0 auto;
}

.article_content_two {
    display: none;
}

.middle_down .article_content_two {
    display: block;
}

.middle_down .article_content {
    display: none;
}

.middle_down .article_content_two .poster {
    float: none;
    margin: 0 auto 0;
    max-width: 875px;
    width: 50%;
}

.middle_down .article_content_two .text {
   max-width: 875px;
   margin: 0 auto 25px;
   display: block;
}

@media ( max-width: 1199px ) {

    .default .article_content .poster, .right .article_content .poster, .left .article_content .poster  {
        float: right;
        margin: 0 0 10px 25px;
        max-width: 35%;
    }
    .default .article_content .text,  .right .article_content .text, .left .article_content .text {
        padding: 0 0 0 0;
    }

    .middle_top .article_content .poster {
        float: none;
        margin: 0 auto 15px;
        max-width: 875px;
        width: 50%;
    }

    .middle_top .article_content .text {
       max-width: 875px;
       margin: 0 auto;
       display: inline-block;
    }

    .article_content_two {
        display: none;
    }
}

@media ( max-width: 768px ) {

    .exhibit_page .article_content {
        display: none;
    }

    .article_content_two {
        display: block;
    }

    .middle_top .article_content_two .text, .default .article_content_two .text,  .right .article_content_two .text {
       max-width: 875px;
       margin: 0 auto;
        display: inline-block;
    }
}
