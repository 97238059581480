/*                                       */
/* BEGIN top-columns section on homepage */
/*                                       */

#top-columns {
    background-color: $white-body;
    padding-bottom: 3rem;
}
#top-columns .row {
    padding-top:0;
    padding-bottom:0;
}

#top-columns .column-items {
    display: flex;
    flex-wrap: wrap;
}
/* Safari doesn't play well with floated elements and flex display */
#top-columns .column-items:before,
#top-columns .column-items:after {
    display:none!important;
}
#top-columns .column-item {
    display: flex;
    flex-flow: column;
}
/* Safari doesn't play well with floated elements and flex display */
#top-columns .column-item:before,
#top-columns .column-item:after {
    display:none!important;
}
#top-columns .column-combo {
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
}
/* Safari doesn't play well with floated elements and flex display */
#top-columns .column-combo:before,
#top-columns .column-combo:after {
    display:none!important;
}
#top-columns .column-sub-item {
    flex-wrap: wrap;
    flex:1;
}

@media (max-width: 991px) {
    #top-columns .column-combo {
        display: block;
    }
    #aal-home {
        margin-top: 0;
    }
}

@media (max-width: 450px) {
    #quicklink-container {
        width: 100%;
    }
}


/* scholarship */
#scholarship {
    text-align: center;
}

#scholarship h2 {
    margin: 0;
    color: $bc-brand-maroon;
    font-size: 20px;
}

#scholarship h3 {
    margin-top: 1.25em;
    font-size: 18px;
}

/* exhibition highlight */
#exhibit-highlight {
    background-color: cyan;
}

/* faculty publication highlight */
#facpub {
    background-color: $white-body;
    padding-bottom: 40px;
}

#facpub h3 {
    font-size: 18px;
}

#facpub .more-link {
    text-align: right;
}

/* digital collections highlight */
#digcol-highlight {
    width: 100%;
    min-height: 150px;
    height: 600px;
    background: url("https://library.bc.edu/images/sc_bg_02_med.jpg") no-repeat 50% 50% $very-dark-gray;
    color: $white-text;
    padding: 55px 0;
    z-index: 2;
    background-size: cover;
}

#digcol-highlight .container-fluid .pull-right .content {
    max-width: 50%;
}

/* digital scholarship highlight */
#ds-highlight {
    display: inline-block;
    width: 100%;
    min-height: 150px;
    height: 600px;
    background: url("https://library.bc.edu/images/digitalscholashipbg-morales.jpg") no-repeat 100% 50% $very-dark-gray;
    border-top: 1px solid $very-light-gray;
    border-bottom: 1px solid $very-light-gray;
    color: $white-text;
    padding: 55px 0;
    z-index: 2;
    background-size: cover;
}

/* custom for DS morales project */
#ds-highlight.rightBottom .container-fluid .pull-right .content {
    background: rgba(0, 0, 0, 0.80) !important;
}

#aal-home {
    margin-top: 0;
    padding-bottom: 0;
    background-color: $white-body;
}
#aal-home .aal-box-small a.aal-icon {
    font-size: 25px!important;
    width: 50px;
}
#aal-home .aal-box a.aal-icon i {
    padding: 12px 15px 13px 11px;
}

@import 'services_highlight';

#staff-interest {
    background-color: $white-body;
    position: relative;
    padding-bottom: 1rem;
}
#staff-interest .photo-list {
    display: flex;
    align-content: stretch;
    flex-wrap: wrap;
}
#staff-interest .photo-panel img {
    width: 100%;
    padding: 0;
    margin: 0 auto;
    min-height: 159px;
}
#staff-interest .photo-panel .caption {
    padding: .5rem;
    font-style: italic;
    text-align: right;
}
#staff-interest .blockquote {
    margin: 2rem 0;
}

@media (min-width: 1200px) {
    #staff-interest .photo-list .photo-panel:last-child {
        display: none;
    }
}

@media (max-width: 1199px) {
    #staff-interest .photo-list img {
        min-height: 126px;
    }
}

@media (max-width: 991px) {
    #staff-interest {
        margin-top: 3rem;
    }
    #staff-interest .photo-list img {
        min-height: 159px;
    }
}
@media (max-width: 767px) {
    #staff-interest .photo-list img {
        min-height: auto;
    }
}
@media (max-width: 525px) {
    #staff-interest .photo-list img {
        min-height: auto;
    }
    #staff-interest .photo-panel {
        width: 100%;
    }
    #staff-interest .photo-list .photo-panel:nth-last-of-type(-n+2) {
        display: none;
    }
}


#aal-home .chat-link {
    min-height: 2.75rem;
}


/* News */

.ql-news {
    padding-bottom: 2rem;
}
#news .news-content-row {
    display: flex;
    flex-wrap: wrap;
}
/* Safari doesn't play well with floated elements and flex display */
#news .news-content-row:before,
#news .news-content-row:after {
    display:none!important;
}

/* Safari doesn't play well with floated elements and flex display */
#news .news-content:before,
#news .news-content:after {
    display:none!important;
}
#news .news-content a, .news-section-unsafe-html-story {
    color: inherit;
}

.news-section-unsafe-html-story {
    width: 100%;
}

.news-section-unsafe-html-embed {
    height: 370px;
    width: 100%;
}

#news .news-content a:hover,
#news .news-content a:focus {
    text-decoration: none;
    color: inherit;
}
#news .news-content .news-section-text {
    padding: 0 .25rem .25rem .25rem;
    line-height: 1.6;
}
#news .news-content .news-section-text .news-section-date {
    font-weight: 600;
    margin: .5rem 0 .25rem 0;
    color: #a25729;
}
#news .news-content .news-section-text .news-section-title {
    font-weight: 400;
    line-height: 1.5;
    margin: .25rem 0;
}
#news .news-content.news-content-large .news-section-text .news-section-title {
    font-size: 2rem;
}

#news .news-content a:hover .news-section-title,
#news .news-content a:focus .news-section-title {
    text-decoration: underline;
}

#news .news-content a:hover,
#news .news-content a:focus {
    opacity: .8;
}

#news .news-content a:focus {
    outline: 1px solid blue;
    outline-offset: 0;
}
#news .news-content img {
    width: 100%;
}

#news .more-news,
.browse-more-link {
    padding-top: 1rem;
    font-size: larger;
}

.homepagerefresh #news .more-news,
.browse-more-link {
    text-align: right;
}

.homepage h2,
.homepage h3 {
    font-weight: 200;
}

/* Quick Links */

.ql-news .ql h2 {
    text-align: center;
    border-bottom: 1px solid $white-body;
}

.ql {
    height: 100%;
    padding-bottom:10px;
}

.ql .list-group {
    margin-bottom: 0;
    list-style: none;
    font-size: 16px;
}

.ql .list-group-item {
    background-color: transparent;
    border: 0 solid $white-body;
    padding: 1.25rem .5rem;
    border-radius: 0;
    color: $black-text;
}

.ql .fa-lg {
    padding-right: 5px;
}

/* Ask A Librarian */

#aal-home-container h3 {
    font-size: 18px;
    font-weight: 400;
}

#aal-home-container .aal-box-small {
    font-size: 16px;
}

.aal-boxes-horizontal-layout .aal-boxes {
    display: flex;
    flex-wrap: wrap;
}

/* Safari doesn't play well with floated elements and flex display */
.aal-boxes-horizontal-layout .aal-boxes::before,
.aal-boxes-horizontal-layout .aal-boxes::after {
    display:none!important;
}

.aal-boxes-horizontal-layout .aal-box-item {
    display: flex;
}

/* Safari doesn't play well with floated elements and flex display */
.aal-boxes-horizontal-layout .aal-box-item::before,
.aal-boxes-horizontal-layout .aal-box-item::after {
    display:none!important;
}

/* At A Glance */

#at-a-glance-container {
    font-size: 16px;
    margin-bottom: 2rem;
}

#at-a-glance-container i {
    padding-right: .5rem;
    font-size: 22px;
}
#at-a-glance-container i#container {
    position: relative;
    padding-right: 1.5rem;
}

#at-a-glance-container i#nested {
    position: absolute;
    top: -10px;
    left: -10px;
    font-size: 200%;
    color: rgba(217, 83, 79, 0.7);
}

#at-a-glance .at-a-glance-alert {
    margin-bottom: 1rem;
    display: block;
}

#at-a-glance .alert {
    padding: 8px 2px 8px 12px;
    margin-bottom: 0;
}

/* Welcome Back Students */
#welcome-back-students-container {
    margin-top: 2rem;
}

#welcome-back-message-wrapper {
    font-size: 16px;
    background-color: #FBCD26;
    color: $black-text;
    padding: 2rem;
    border-radius: 8px;
}

#welcome-back-message-wrapper h2 {
    color:$black-text;
    padding: 0 0 1rem 0;
    margin: 0;
}


/* Featured Tip */

#featured-tip-container {
    margin-top: 2rem;
}

#featured-tip-wrapper {
    font-size: 16px;
    background-color: $bc-secondary-bright-sage;
    color: $black-text;
    padding: 2rem;
    border-radius: 8px;
}

#featured-tip-wrapper h2 {
    color: $black-text;
    padding: 0 0 1rem 0;
    margin: 0;
}

#featured-tip-wrapper .panel {
    color: $black-text;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    border: 1px solid rgba(0,0,0,0.1);
}
#featured-tip-wrapper .panel-body {
    padding: .75rem;

}
#featured-tip-wrapper hr {
    margin-top: .5rem;
    margin-bottom: .5rem;
    border: none;
    border-bottom: 1px solid #ccc;
    line-height: 1px;
}

#featured-tip-wrapper .caption {
    font-size: 10px;
    text-align: right;
}

#featured-tip-wrapper .panel a {
    font-weight: 600;
}

#featured-tip-wrapper h3 {
    margin: 0 0 1rem 0;
    padding: 0;
    line-height: 1.25;
}

#featured-tip-wrapper img {
    padding: 3px;
    width: 100%;
}

#featured-tip-wrapper .intro {
    margin: 0;
}

#featured-tip-wrapper .outro {
    text-align: right;
    margin: 0;
}

#featured-tip-wrapper .outro.left{
    text-align: left;
}

#featured-tip-wrapper .outro a {
    color: $black-text;
    text-decoration: none;
    border-bottom: 1px solid $black-body;
    line-height: 1;
}

#featured-tip-wrapper .outro a:hover,
#featured-tip-wrapper .outro a:focus {
    color: blue;
    border-bottom: 1px solid blue;
}

#featured-tip-wrapper .outro strong {
    font-weight:600;
}

#featured-tip-wrapper .related-topics {
    font-size: smaller;
}

#featured-tip-wrapper {
    .flexbox {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .flex-element {
            display: flex;
            flex-grow: 1;
        }
    }
}

/* Welcome students - services highlight */

@media (max-width: 500px) {
    #featured-tip-wrapper {
        .flexbox {
            flex-direction: column;
        }
    }
 }


/* Welcome new students */

#welcome-new-students {
    margin-bottom: 4rem;
}

#welcome-new-students .intro {
    font-size: large;
}

/* Visitation/Vaccination alert box */

#visitation-alert {
    background-color: #f8c21c;
    border-bottom: 1px solid #f8c21c;
    border-radius: 8px;

    #visitation-alert-wrapper {
        padding: 1.25rem;

        h2 {
            margin: 0;
            padding: 0;
        }

        a.underline {
            font-weight: 700;
            color: #622a27;
            text-decoration: none;
            border-bottom: 1px solid #622a27;

            &:hover, &:focus {
                color:blue;
                border-bottom: 1px solid blue;
            }
        }

        .visitation-alert__heading {
            h2 {
                font-size: 16px;
                line-height: 18px;
                font-weight: 600;
                padding: 0;
                vertical-align: middle;
            }

            .visitation-alert__date {
                font-size: 12px;
            }
        }

        .visitation-alert__content {
            font-size: 16px;
            line-height: 18px;
        }

        .visitation-alert__link {
            text-align: right;
        }

        .visitation-alert__dismiss {
            text-align: right;

            button {
                background: none!important;
                border: none;
                padding: 0!important;
                font-family: arial, sans-serif;
                color: #069;
                cursor: pointer;
            }
        }
    }
}

/* highlight services for students */
#featured-tip-wrapper.resource-highlights {
    .featured-tip-element {
        min-height: 392px;
        position: relative;
    }

    .overlay-img {
        max-height: 350px;
        overflow: hidden;
        border-radius: 4px;
    }

    a.panel-overlay {
        display: block;

        &:hover, &:focus {
            outline: 2px solid blue;
            border-radius: 2px;
            text-decoration: underline;
        }
    }

    .overlay-text h3 {
        position: absolute;
        top: 30px;
        right: 34px;
        text-align: right;
        font-size: 28px;
        font-weight: 400;
        padding: 1rem;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.75);
        text-shadow: 0 2px 6px #000;
    }
}

@media (max-width: 1199px) {
    #featured-tip-wrapper.resource-highlights {
        .featured-tip-element {
            min-height: 331px;
        }
    }
}

@media (max-width: 991px) {
    #featured-tip-wrapper.resource-highlights {
        margin-bottom: 3rem;

        .featured-tip-element {
            min-height: auto;
        }
    }
    #visitation-alert {
        #visitation-alert-wrapper {
            .visitation-alert__heading {
                h2 {
                    padding-bottom: .75rem;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    #featured-tip-wrapper.resource-highlights {
        margin-bottom: 2rem;
    }
}

@media (max-width: 425px) {
    #featured-tip-wrapper.resource-highlights {
        .overlay-text h3 {
            font-size: 20px;
        }
    }
}

@media (max-width: 300px) {
    #featured-tip-wrapper.resource-highlights {
        .overlay-text h3 {
            font-size: 16px;
        }
    }
}

@import 'featured-subject-specialists';

/* Explore collections */

#explore-collections {
    height: auto;
    background: $white-body;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
#explore-collections h2 {
    color: $black-text;
}
#explore-collections .row {
    padding-top: 0;
    padding-bottom: 0;
}
#explore-collections .collections-box-container {
    margin-top: 2rem;
}
#explore-collections .collections-box {
    margin: 0 0 2.5rem 0;
    position: relative;
}

#explore-collections .collections-box a {
    display: block;
}
#explore-collections .collections-box a img {
    transition: all 0.7s ease;
    opacity: .85;
    display: block;
}

#explore-collections .collections-box a:hover,
#explore-collections .collections-box a:focus {
    text-decoration:none;
}

#explore-collections .collections-box a:hover img,
#explore-collections .collections-box a:focus img {
   opacity:1;
}

/* set the header color based on department class */
#explore-collections .collection-description {
    padding: 1.25rem 1rem 1.25rem 1.25rem;
    background-color: #B35F23;
    color: $white-text;
}
#explore-collections .digital-scholarship .collection-description {
    background-color: #204375;
}
#explore-collections .escholarship .collection-description {
    background-color: #B35F23
}
#explore-collections .digital-collections .collection-description {
    background-color: #467282;
}

#explore-collections .collection-department,
#explore-collections .collection-name {
    color: $white-text;
    line-height: 1.2;
}
#explore-collections .collection-department {
    font-size: 15px;
    font-weight: 600;
    padding-bottom: .5rem;
}
#explore-collections .collection-name {
    font-size: 18px;
    min-height: 4.75rem;
}
#explore-collections .collection-image img {
    width: 100%;
}

@media (max-width: 425px) {
    #explore-collections .collections-box {
        width: 100%;
    }
}


/* Social Media Highlight */
#socialmedia-highlight .highlight-title h2 {
    margin: 0 0 3rem 0;
    color: $white-text;
    display: inline-block;
}
#socialmedia-highlight .leftBottom .container-fluid .pull-right .content,
#socialmedia-highlight .leftTop .container-fluid .pull-right .content,
#socialmedia-highlight .rightTop .container-fluid .pull-right .content,
#socialmedia-highlight .rightBottom .container-fluid .pull-right .content {
    color: $white-text;
    display: table;
    padding: 20px 30px;
    max-width: 50%;
    background: rgba(0, 0, 0, 0.875);
}
#socialmedia-highlight .content-body {
    margin-top: 2rem;
    line-height: 1.6;
}
#socialmedia-highlight .content-body .label {
    padding: 2rem 0 0 0;
    font-size: 100%;
    color: #aaa;
}
#socialmedia-highlight .content-body a {
    text-decoration: underline;
    font-weight: 600;
}
#socialmedia-highlight .connect-button {
    margin-top: 3rem;
}
#socialmedia-highlight .content-body .image-attribution {
    font-size: small;
    margin-top: 4rem;
}

/* media queries */
@media (max-width: 1199px) {
    .news-section-unsafe-html-embed {
        height: 303px;
    }
}

@media (max-width: 991px) {
    .ql {
        height: auto;
        margin-bottom: 20px;
    }
    .ql li {
        display: block;
        font-size: larger;
    }

    .news-section-unsafe-html-embed {
        height: 230px;
    }
}
@media (min-width: 992px) {
    .ql .list-group {
        font-size: large;
    }
}
@media (max-width: 767px) {
    .featured-tip-element .panel img.featured-tip-image {
        display: none;
    }
}
@media (max-width: 500px) {
    .ql .list-group {
        width: 100%;
    }
}
@media (max-width: 450px) {
   #at-a-glance-container {
       width: 100%;
   }
}
