/* FACULTY PUBLICATION HIGHLIGHT STYLES */

#titleauthor h2, #titleauthor h3 {
    margin: 5px 0 10px 0;
}

.facpub_wrap {
    h3 {
        font-size: 18px;
    }
}

.facpub_wrap .article_page {
    margin: 0;
}

.facpub_wrap {
    width: 1080px;
    margin: 0 auto 0;
    background: $white-body;
}

.fph_header {
  background: url("https://library.bc.edu/images/facpub/fph_header.jpg") no-repeat scroll center center $black-body !important;
  min-height: 200px !important;
  height: 200px !important;
}

.fph_header h1 {
    color: $white-text;
    line-height: 1;
    margin: 0;
    text-align: center;
    font-size: 3em;
    text-shadow: 2px 2px 1px $black-text;
}

.fph_header a:hover {
    text-decoration: none;
}

.breadcrumb {
    background-color: $white-body;

    &>.active {
        color: $wcagaa-compliant-gray-text;
    }
}

/* INDIVIDUAL PAGES */

.facpub_wrap .breadcrumb {
    background: $white-body;
    padding-left: 0;
    padding-right: 0;
    margin: .5rem 0 1rem 0;
}

.facpub_wrap #titleauthor {
    font-size: 20px;
    line-height: 1;
    padding: 0 0 0 0;
    text-align: left;
    margin: 0 0 15px 0;
}
.facpub_wrap #titleauthor h2 {
    font-size: 24px;
    color: $red;
}

.facpub_wrap .youtube {
    width: 100%;
    background: none repeat scroll 0 0 $very-light-gray;
    border-bottom: 1px solid $very-light-gray;
    color: $black-text;
    display: block;
    margin: 0 0 10px;
    overflow: hidden;
    padding: 20px;
    text-align: center;
}

.facpub_wrap #content_container {
    width: 100%;
    border-top: 0;
    border-bottom: 0;
    margin: 0 0 0;
    display: block;
}

.facpub_wrap .article_content {
    width: 100%;
    padding: 10px 0;
}

.facpub_wrap .article_content .fph_content_left {
    border-right: 1px solid $very-light-gray;
    padding: 0 2rem 0 0;
    text-align: justify;
}

.facpub_wrap .article_content .fph_content_right {
    padding-left: 2rem;
}

.facpub_wrap .article_content .fph_content_right p, .facpub_wrap .article_content .fph_content_right p.yes {
    line-height: 1.5;
}

.facpub_wrap .article_content .fph_content_right p.no /* USES MARKDOWN TO HIDE PARAGRPAH IF IS THERE IS NO ENTRY */
    {
   display: none;
}

.facpub_wrap .article_content .fph_content_right .prof_title {
    display: inline-block;
    border-bottom: 1px solid $very-light-gray;
    margin: 0 0 10px 0;
    width: 100%;
    padding: 0 0 10px 0;
}

.facpub_wrap .article_content .fph_content_right img {
    margin: 0 10px 10px 0;
    padding: 1px;
    width: 125px;
    height: auto;
    min-height: 200px;
    float: left;
    border: 1px solid #666;
}

.facpub_wrap .sample:after {
    font-family:'FontAwesome';
    content:"\f1c1";
    display: inline-block;
    text-decoration: none;
    margin: 0 5px;
}

.facpub_wrap .location:after {
    font-family:'FontAwesome';
    content: "\f08e";
    display: inline-block;
    text-decoration: none;
    margin: 0 5px;
}

.facpub_wrap .librarian {
    margin: 2rem 0 2rem 0;
    padding: 1rem 0;
    text-align: center;
    border-top: 1px solid $very-light-gray;
    background: $very-light-gray;
    a {
        color: $wcgaa-link-against-very-light-gray;
    }
}

.facpub_wrap .row {
    margin-left: 0;
    margin-right: 0;
    padding: 0;
}


/* LATEST HIGHLIGHTS */

.latest-highlight {
    width: 100%;
    display: block;
    margin: 0 0 10px 0;
    overflow: hidden;
    padding: 0 0 25px 0;
    position: relative;
    height: auto;
    border-bottom: 1px solid $very-light-gray;
}

.latest-highlight h2 {
    margin: 0 0 20px 0;
}

.latest-highlight .fph_content_left {
    border-right: 1px solid $very-light-gray;
    height: 100%;
    padding: 0 2rem 0 0;
    text-align: justify;
}

.latest-highlight .fph_content_right {
    height: 100%;
    padding: 0 0 0 2rem;
    text-align: justify;
}

.latest-highlight img {
    border: 2px solid $dark-blue-green;
    float: left;
    height: 135px;
    margin: 0 10px 0 0;
    padding: 1px;
}

.facpub .latest-highlight #titleauthor {
    font-size: 15px;
    line-height: 1;
    padding: 0;
}

.view-interview {
    position: absolute;
    bottom: 0;
    right: 0;
}

.view-interview:after {
    font-family:'FontAwesome';
    content:"\f04b";
    display: inline-block;
    text-decoration: none;
    margin: 0 0 0 8px;
}

.facpub_archives {
    margin: 0 0 4rem 0;
    position: relative;
    display: inline-block;
    padding: 0;
}

.old_facpub {
    width: 50%;
    height: 135px;
    position: relative;
    display: inline-block;
    float: left;
    padding: 10px 0;
    border-bottom: 1px solid $very-light-gray;
    overflow: hidden;
}

.old_facpub .bookinfo p {
    font-size: 14px;
    line-height: 1.2;
}

.old_facpub img {
    width: 75px;
    float: left;
    border: 2px solid $dark-blue-green;
    padding: 1px;
    margin: 0 5px 0 0;
}

.older_archives {
    width: 100%;
    position: relative;
    display: inline-block;
    margin: 4rem 0 0 0;
    padding: 1.5rem 0 1rem 0;
    border-top: 1px solid $very-light-gray;
    text-align: center;
}

.yearlyblock {
    display: block;
    width: 100%;
    height: auto;
    margin-top: 4rem;
}

.yearlyblock .row {
    display: flex;
    align-content: stretch;
    flex-wrap: wrap;
}

.yearlyblock:first-of-type {
    margin-top: 2rem;
}

.yearlyblock .archives {
    border-top: 1px solid $very-light-gray;
    padding: 1.5rem 0 5rem 0;
}

.archive-item {
    min-height:145px;
    border-top: 1px solid $very-light-gray;
    border-bottom: 1px solid $very-light-gray;
    padding: 1.5rem 0 .5rem 0;
    line-height: 1.25;
    overflow: initial;
    margin: -1px 0 0;

    a:hover {
        text-decoration: none;

        .archive-item-title {
            text-decoration: underline;
        }
    }
}

.archive-item-title {
    padding-right: .75rem;
}

.archive-item-authors {
    padding-top: .25rem;
    color: $black-text;
    text-decoration: none;
}

a:hover .media {

}

a:hover .archive-item-authors {
  text-decoration: none;
}

.archive-item img {
    width:75px; padding: 1px; border:2px solid $dark-blue-green;
}

.archive-item .media-body {
    padding-right: .5rem;
}

/* RESPONSIVE PORTION */

@media ( max-width: 1079px ) {

    .facpub_wrap {
        width: 100%;
        padding: 0 10px;
        min-width: 420px;
    }

    .fph_header {
        height: auto;
        max-height: 200px;
        min-height: 50px;
        overflow: hidden;
    }
}
@media ( max-width: 767px ) {
    .facpub_wrap .article_content .fph_content_left {
        border: none;
        margin: 0 0 2rem 2rem;
    }
}

@media ( max-width: 640px ) {

    .fph_header h1 {
        font-size: 2em;
        text-shadow: 1px 1px 1px $black-text;
    }

    .latest-highlight {
        height: auto;
    }

    .facpub_wrap .article_content .fph_content_left,  .latest-highlight .fph_content_left {
        float: none;
        border-right: 0 solid $very-light-gray;
        border-bottom: 0 solid $very-light-gray;
        padding: 0 5px;
        width: 100%;
        height: auto;
    }
    .facpub_wrap .article_content .fph_content_right {
        float: none;
        width: 100%;
        height: auto;
        padding: 10px 0 0 0;
    }
    .latest-highlight .fph_content_right {
        display: none;
    }
    .facpub_wrap .article_content .fph_content_right p {
        width: 100%;
    }

    .view-interview {
        display: none;
    }
    .old_facpub:nth-child(2n+1) {
        border-right: 0 solid $very-light-gray;
    }
    .old_facpub:nth-last-child(2) {
        border-bottom: 1px solid $very-light-gray;
    }
    .old_facpub {
        width: 100%;
    }
}


/* RESPONSIVE YOUTUBE EMBED CODE -
COULD BE USEFUL ELSEWHERE  */

.video-container {
    position: relative;
    max-width: 853px;
    max-height: 480px;
    margin: 0 auto;
}

@media ( max-width: 1199px ) {
    .video-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;
        max-width: 853px;
        max-height: 480px;
    }

    .video-container iframe,
    .video-container object,
    .video-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
